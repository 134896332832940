
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, reactive, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "create-class-modal",
  components: {
  },
  props:{
    targetDate: Date || null,
    students:[] as any,
    subjectId : Number,
    getAttendance : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);
    const attendanceDate = ref<any>([]);

    watch(() => props.targetDate, (cur, prev)=>{
        attendanceDate.value[0] = props.targetDate;
        attendanceDate.value[1] = props.targetDate;
    })

    const statusInfo = reactive({
      selectedStatus:'Present',
      statusList :['Select','Present','Late','Absent','Early','LOA']
    });

    const selectedStudents = ref<any>([]);
    selectedStudents.value= [];

    const isSelectAll = (e) => {
        if(e.includes('all')){
          selectedStudents.value = props.students.map(item => item.student_id);
        }
        if(e.includes('unall')){
          selectedStudents.value = [];
        }
    }

    const resetData = () =>{
      statusInfo.selectedStatus = "Present";
      selectedStudents.value=[];
      attendanceDate.value[0] = '';
      attendanceDate.value[1] = '';
    }

    const updateAttendances = async() =>{
        if(!attendanceDate.value[0] || !attendanceDate.value[1] || selectedStudents.value.length ==0 || !statusInfo.selectedStatus ){
            Swal.fire({
              text: "Please fill out all required fields",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
        }

        const formData :any = { subject_id: props.subjectId, attendance_status: statusInfo.selectedStatus, student_ids: selectedStudents.value.map(x=> x), start_date : attendanceDate.value[0], end_date: attendanceDate.value[1]};
        
        ApiService.setHeader();
        await ApiService.post("attendance/bulk", formData)
            .then(() => {
                props.getAttendance();
                resetData();
                let editCloseButton = document.getElementById('edit_class_close_button');
                editCloseButton?.click();
            })
            .catch(({ response }) => {
                console.log('bulk attendance create error => '+response.data.errors);
            });
    }

    return {
        statusInfo,
        isSelectAll,
        selectedStudents,
        attendanceDate,
        loading,
        updateAttendances
    };
  },
});
