<template>
  <!--begin::Modal - Create App-->
  <div
    class="modal fade"
    id="modal_update_attendance"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-900px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Update Attendance</h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            id="edit_class_close_button"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
            <div class="row mb-6">
                <label class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4">
                    <span class="required">Date range</span>
                </label>
                <div class="col-lg-8">
                    <el-date-picker
                      v-model="attendanceDate"
                      type="daterange"
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                      class="w-100" 
                      format="MM/DD/YYYY">
                    </el-date-picker>
                </div>
            </div>
            <div class="row mb-6">
                <label class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4">
                    <span class="required">Status</span>
                </label>
                <div class="col-lg-8">
                    <el-select
                        class="form-select-solid w-100"
                        placeholder="Select attendance status"
                        v-model="statusInfo.selectedStatus"
                        filterable=true
                        default-first-option>
                        <el-option v-for="status in statusInfo.statusList" 
                            :label="status"   
                            :value="status" 
                            :key="status">
                            {{status}}
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row mb-6">
                <label class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4">
                    <span class="required">Student</span>
                </label>
                <div class="col-lg-8">
                    <el-select @change="isSelectAll" multiple v-model="selectedStudents" placeholder="Students">
                        <el-option v-if="students.length > 0" label="name" :value="selectedStudents.length === ( students ? students.length :0) ? 'unall' : 'all'">
                            <span style="float: left">{{selectedStudents.length === ( students ? students.length :0) ? 'Unselect all' : 'Select all'}}</span>
                        </el-option>
                        <el-option v-for="student in students" 
                          :key="student.student_id" 
                          :label="student.name" 
                          :value="student.student_id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            
          <div class="d-flex flex-stack pt-10" style="float:right;">
              <el-button type="primary" :loading="loading" @click="updateAttendances">
                  {{ loading ? "Please wait..." : "Submit" }}
              </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, reactive, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "create-class-modal",
  components: {
  },
  props:{
    targetDate: Date || null,
    students:[] as any,
    subjectId : Number,
    getAttendance : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);
    const attendanceDate = ref<any>([]);

    watch(() => props.targetDate, (cur, prev)=>{
        attendanceDate.value[0] = props.targetDate;
        attendanceDate.value[1] = props.targetDate;
    })

    const statusInfo = reactive({
      selectedStatus:'Present',
      statusList :['Select','Present','Late','Absent','Early','LOA']
    });

    const selectedStudents = ref<any>([]);
    selectedStudents.value= [];

    const isSelectAll = (e) => {
        if(e.includes('all')){
          selectedStudents.value = props.students.map(item => item.student_id);
        }
        if(e.includes('unall')){
          selectedStudents.value = [];
        }
    }

    const resetData = () =>{
      statusInfo.selectedStatus = "Present";
      selectedStudents.value=[];
      attendanceDate.value[0] = '';
      attendanceDate.value[1] = '';
    }

    const updateAttendances = async() =>{
        if(!attendanceDate.value[0] || !attendanceDate.value[1] || selectedStudents.value.length ==0 || !statusInfo.selectedStatus ){
            Swal.fire({
              text: "Please fill out all required fields",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
        }

        const formData :any = { subject_id: props.subjectId, attendance_status: statusInfo.selectedStatus, student_ids: selectedStudents.value.map(x=> x), start_date : attendanceDate.value[0], end_date: attendanceDate.value[1]};
        
        ApiService.setHeader();
        await ApiService.post("attendance/bulk", formData)
            .then(() => {
                props.getAttendance();
                resetData();
                let editCloseButton = document.getElementById('edit_class_close_button');
                editCloseButton?.click();
            })
            .catch(({ response }) => {
                console.log('bulk attendance create error => '+response.data.errors);
            });
    }

    return {
        statusInfo,
        isSelectAll,
        selectedStudents,
        attendanceDate,
        loading,
        updateAttendances
    };
  },
});
</script>
<style scoped>
*>>> .el-select__tags
{
  max-height:150px;overflow-y: scroll
}
</style>
